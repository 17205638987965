<template>
  <v-row justify="center" justify-md="end" no-gutters>
    <v-col cols="12" sm="10" md="8" class="px-6 px-sm-0">
      <!-- Title -->
      <label class="text-h1 font-weight-bold quaternary--text">{{
        $t('errorCode.403')
      }}</label>
      <!-- Message -->
      <div class="mt-6">
        <p class="text-h4 font-weight-bold">
          {{ $t('message.userNotAuthorizedMessage_1') }}
        </p>
        <p class="text-h6 font-weight-regular text--label">
          {{ $t('message.userNotAuthorizedMessage_2') }}
        </p>
      </div>
      <!-- Actions -->
      <a
        @click="backToInitialPage"
        class="tertiary--text text-decoration-underline"
        >{{ $t(`text.goToPage${pageToGo}`) }}</a
      >
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import { AUTH_IS_AUTHENTICATED } from '@/store/auth.module'

export default {
  name: 'Error404',
  computed: {
    ...mapGetters({
      isAuthenticated: [AUTH_IS_AUTHENTICATED]
    }),
    pageToGo() {
      if (this.isAuthenticated) {
        return 'Home'
      } else {
        return 'Login'
      }
    }
  },
  methods: {
    backToInitialPage() {
      this.$parent.$parent.backToInitialPage()
    }
  }
}
</script>

<style lang="scss"></style>
